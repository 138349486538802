import { Column, Container, Row } from '@/components/base/gridview';
import { motion } from 'framer-motion';
import DiscoverMap from './map/DiscoverMap';
import DiscoverCategories from './categories/DiscoverCategories';
import { UIDiscover, UIRegion } from './types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { functions, useApp } from '@wap-client/core';
import { UIDiscoverCity } from './categories/types';
import { Tab, Tabs } from '@/components/base/tabs';
import useDeviceType from '@/hooks/useDeviceType';
import { queryService } from '@wap-client/services';
import { CITY, REGION } from '../constants';
import Search from './search/Search';
import { InView } from 'react-intersection-observer';

const animations = {
  main: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.2,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const Discover: React.FC<UIDiscover> = ({
  isMobileMenu = false,
  showList = true,
  regions = [],
}) => {
  const deviceType = useDeviceType();
  const app = useApp();

  const [isFetched, setFetched] = useState<boolean>(false);
  const [activeCity, setActiveCity] = useState('');
  const [activeRegion, setActiveRegion] = useState<UIRegion | undefined>(
    regions[0]
  );
  const [activeList, setActiveList] = useState(showList);
  const [allCities, setAllCities] = useState<UIDiscoverCity[]>([]);

  // const filteredCitiesByRegion = useMemo(
  //   () => allCities.filter((city) => city.region?.id === activeRegion?.id),
  //   [allCities, activeRegion]
  // );

  const onChangeTab = (id: string) => {
    if (!regions) {
      return;
    }

    const activeRegion = regions.find((region) => region.id === id);
    if (activeRegion) {
      setActiveRegion(activeRegion);
    }
  };

  const handleList = () => {
    setActiveList(!activeList);
  };

  const fetchAllCities = useCallback(async (language?: any) => {
    try {
      const response = await queryService.run<UIDiscoverCity[]>(
        app.environment,
        {
          name: 'mapsorgusu',
          language: language,
        },
        CITY
      );

      if (response) {
        setAllCities(response.data);
      }
    } catch (err) {
      err;
    }
  }, []);

  const onChangeInView = (inView: boolean) => {
    if (!inView) return;
    setFetched(true);
    fetchAllCities(app.language);
  };

  useEffect(() => {
    setActiveRegion(regions[0]);
  }, [regions]);

  return (
    <section
      className={functions.classnames(
        'discover',
        isMobileMenu && 'is-mobil-menu'
      )}
    >
      {!isFetched && <InView onChange={onChangeInView} />}
      <Container size={isMobileMenu ? 'full' : 'wide'}>
        <nav className="discover-nav">
          <Row xs={{ justify: 'between', align: 'center' }}>
            <Column>
              <Tabs
                direction="row"
                align="left"
                onChange={onChangeTab}
                activeId={activeRegion ? activeRegion.id : ''}
              >
                {regions.map((region, index) => (
                  <Tab id={region.id && region.id} key={index}>
                    {region.title}
                  </Tab>
                ))}
              </Tabs>
            </Column>
            {(deviceType === 'desktop' || deviceType === 'laptop') &&
              isMobileMenu === false && (
                <Column xs={{ auto: true }}>
                  <Search
                    onChangeTab={onChangeTab}
                    activeRegion={activeRegion}
                    setActiveCity={setActiveCity}
                  />
                </Column>
              )}
          </Row>
        </nav>
        <motion.div
          className="discover-main"
          variants={animations.main}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.1 }}
        >
          <DiscoverMap
            handleList={handleList}
            activeRegion={activeRegion}
            activeCity={activeCity}
            setActiveRegion={setActiveRegion}
            regions={regions}
            cities={allCities}
          />
          {activeList && <DiscoverCategories cities={activeRegion?.cities} />}
        </motion.div>
      </Container>
    </section>
  );
};

export default Discover;
