import { ReferenceMapping } from '@wap-client/services';

export const CITY: ReferenceMapping[] = [
  {
    name: 'id',
    path: 'id',
    type: 'static',
  },
  {
    name: 'title',
    path: 'baslik',
    type: 'static',
  },
  {
    name: 'thumbnail',
    path: 'image',
    type: 'static',
  },
  {
    name: 'lat',
    path: 'lat',
    type: 'static',
  },
  {
    name: 'lng',
    path: 'lng',
    type: 'static',
  },
  {
    name: 'lng',
    path: 'lng',
    type: 'static',
  },
  {
    name: 'anchor',
    path: 'page',
    type: 'link',
  },
  {
    name: 'region',
    path: 'region',
    type: 'static',
  },
];

export const SEARCH_CITY: ReferenceMapping[] = [
  {
    name: 'value',
    path: 'id',
    type: 'static',
  },
  {
    name: 'label',
    path: 'baslik',
    type: 'static',
  },
  {
    name: 'region',
    path: 'region.id',
    type: 'static',
  },
];

export const REGION: ReferenceMapping[] = [
  {
    name: 'title',
    path: 'baslik',
    type: 'static',
  },
  {
    name: 'id',
    path: 'id',
    type: 'static',
  },
  {
    name: 'lat',
    path: 'lat',
    type: 'static',
  },
  {
    name: 'lng',
    path: 'lng',
    type: 'static',
  },
  {
    name: 'cities',
    path: 'cities',
    type: 'static',
  },
];

export const SEARCH: ReferenceMapping[] = [
  {
    name: 'title',
    path: 'baslik',
    type: 'static',
  },
  {
    name: 'id',
    path: 'id',
    type: 'static',
  },
  {
    name: 'image',
    path: 'thumbnail',
    type: 'static',
  },
  {
    name: 'description',
    path: 'description',
    type: 'static',
  },
  {
    name: 'breadcrumb',
    path: 'breadcrumb',
    type: 'link',
  },
  {
    name: 'anchor',
    path: 'page',
    type: 'link',
  },
];
