import React, { useState, useEffect } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  GeographyProps,
  ProjectionConfig,
} from 'react-simple-maps';
import turkeyMap from './tr.json';
import useDeviceType from '@/hooks/useDeviceType';
import Icon from '@/components/base/icon';
import CustomButton from '@/components/widgets/custom-button/CustomButton';
import { UIDiscoverMap } from './types';
import { motion } from 'framer-motion';
import DynamicAnnotation from './Annotation';

const animations = {
  map: {
    hidden: {
      opacity: 0,
      y: 100,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const DiscoverMap: React.FC<UIDiscoverMap> = ({
  activeRegion,
  activeCity,
  regions,
  cities,
  setActiveRegion,
  handleList,
}) => {
  const deviceType = useDeviceType();

  const [position, setPosition] = useState<{
    coordinates: [number, number];
    zoom: number;
  }>({
    coordinates: [28.9784, 41.0082],
    zoom: 1,
  });

  const defaultColor = '#CCE5E5';
  const activeColor = '#93CCDB';
  const selectColor = '#00708C';

  useEffect(() => {
    const lat = Number(activeRegion?.lat || 0);
    const lng = Number(activeRegion?.lng || 0);
    setPosition({
      coordinates: [lat, lng],
      zoom: 1,
    });
  }, [activeRegion]);

  const handleClick = (geo: GeographyProps['geography']) => {
    const geoRegion = geo.properties.region;
    const region = regions?.find((region) => region.id === geoRegion);
    if (region) {
      setActiveRegion(region);
    }
  };

  const handleMoveEnd = (newPosition: {
    coordinates: [number, number];
    zoom: number;
  }) => {
    setPosition(newPosition);
  };

  const projectionConfig: ProjectionConfig = {
    scale: 6000,
    center: [35.5, 39],
  };

  return (
    <motion.div className="discover-main-map" variants={animations.map}>
      <ComposableMap
        projectionConfig={projectionConfig}
        style={{ width: '100%', height: '100%' }}
      >
        <ZoomableGroup
          center={position.coordinates}
          zoom={position.zoom}
          onMoveEnd={handleMoveEnd}
          minZoom={1}
          maxZoom={2}
          style={{ transition: 'all 0.5s ease' }}
        >
          <Geographies geography={turkeyMap}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const city = geo.properties.id;
                const region = geo.properties.region;

                const isActiveRegion = region === activeRegion?.id;
                const isActiveCity = city === activeCity;

                const fillColor = isActiveCity
                  ? selectColor
                  : isActiveRegion
                  ? activeColor
                  : defaultColor;

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onClick={() => handleClick(geo)}
                    stroke="#FFF"
                    style={{
                      default: {
                        fill: fillColor,
                      },
                      hover: { fill: '#93CCDB' },
                      pressed: { fill: '#1980DD' },
                    }}
                  />
                );
              })
            }
          </Geographies>

          {cities &&
            cities.length > 0 &&
            cities.map((city, index) => {
              const getSubject = turkeyMap.features.find(
                (item) => item.properties.id === city.id
              );

              if (city.region?.id === activeRegion?.id) {
                return (
                  getSubject && (
                    <DynamicAnnotation
                      key={index}
                      text={city.title}
                      subject={{
                        lat: Number(city.lng) || 0,
                        lng: Number(city.lat) || 0,
                      }}
                      dx={getSubject.properties.dx}
                      dy={getSubject.properties.dy}
                      box={{
                        dx: getSubject.properties.box?.dx,
                        dy: getSubject.properties.box?.dy,
                      }}
                      link={city.anchor}
                    />
                  )
                );
              }
            })}
        </ZoomableGroup>
      </ComposableMap>

      <div className="discover-main-map-buttons">
        <CustomButton
          className="discover-main-map-buttons-view"
          background="#FFF"
          round={4}
          iconBefore={<Icon size={'small'} name="icon-view-map" />}
        >
          View on map
        </CustomButton>

        {deviceType !== 'desktop' && (
          <CustomButton
            className="discover-main-map-buttons-list"
            background="#FFF"
            round={4}
            onClick={handleList}
            iconBefore={<Icon size={'small'} name="icon-menu-list" />}
          />
        )}
      </div>
    </motion.div>
  );
};

export default DiscoverMap;
