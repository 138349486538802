import React, { useEffect, useState } from 'react';
import Spinner from '@/components/base/spinner/Spinner';
import Card from '../../card';
import { UIDiscoverCities } from './types';
import { motion } from 'framer-motion';

const animations = {
  categories: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.3,
        staggerChildren: 0.1,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const DiscoverCategories: React.FC<UIDiscoverCities> = ({ cities }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => {
      setLoading(false);
    });

    return () => clearTimeout(timer);
  }, [cities]);

  return (
    <motion.div
      className="discover-main-categories"
      variants={animations.categories}
    >
      {loading ? (
        <Spinner />
      ) : cities && cities.length > 0 ? (
        cities.map((city, index) => (
          <Card
            key={index}
            motion={{
              initial: 'hidden',
              whileInView: 'show',
              viewport: { once: true },
            }}
            isDiscover={true}
            className="discover-main-categories-card"
            title={city.title}
            image={city.thumbnail}
            link={city.anchor}
          />
        ))
      ) : (
        <>No Records Yet</>
      )}
    </motion.div>
  );
};

export default DiscoverCategories;
