import React, { useCallback, useEffect, useState } from 'react';
import ReactSelect, {
  ClearIndicatorProps,
  components,
  InputProps,
  SingleValue,
  StylesConfig,
  PropsValue,
  ValueContainerProps,
  SingleValueProps,
} from 'react-select';

import { queryService } from '@wap-client/services';
import { useApp } from '@wap-client/core';

import Icon from '@/components/base/icon';

import { SEARCH_CITY } from '../../constants';
import { UIDiscoverSearch, UIDiscoverSearchOption } from './types';

const DiscoverSearch: React.FC<UIDiscoverSearch> = ({
  onChangeTab,
  setActiveCity,
  activeRegion,
}) => {
  const app = useApp();

  const [cities, setCities] = useState<UIDiscoverSearchOption[]>([]);
  const [option, setOption] = useState<UIDiscoverSearchOption | null>(null);

  const customStyles: StylesConfig<UIDiscoverSearchOption> = {
    control: (styles) => ({
      ...styles,
      minHeight: '38px',
      minWidth: '54px',
      border: `1px solid #f0f0f0`,
      borderRadius: '100px',
      fontSize: '18px',
      fontWeight: '500',
      color: '#000',
      width: '185px',
      padding: '2px 16px 2px 16px',
      backgroundColor: option ? app.theme.colors.primary['75'] : 'var(--LN0)',
      cursor: 'pointer',
      ':hover': {
        border: `1px solid ${app.theme.colors.primary['75']}`,
      },
      outline: 'none',
      boxShadow: 'none',
      flexDirection: 'row-reverse',
    }),

    clearIndicator: (styles: any) => ({
      ...styles,
      position: 'absolute',
      right: 15,
      color: '#000',
      borderRadius: 100,
      background: '#FFF',
      width: '20px',
      height: '20px',
      padding: '0',
    }),

    placeholder: (styles) => ({
      ...styles,
      color: '#000',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1,
    }),

    input: (styles) => ({
      ...styles,
      margin: 0,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1,
      color: option ? '#ffffff' : '#7c7c7c',
    }),

    menu: (styles) => ({
      ...styles,
      zIndex: 10,
    }),

    option: (styles, { isSelected, isFocused }) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '14px',
      padding: '10px 12px',
      cursor: isSelected ? 'auto' : 'pointer',
      backgroundColor: isSelected
        ? app.theme.colors.primary['75']
        : isFocused
        ? app.theme.colors.primary['200']
        : 'transparent',

      ':hover': {
        backgroundColor: isSelected
          ? app.theme.colors.primary['75']
          : app.theme.colors.primary['200'],
      },
      color: isSelected ? 'white' : isFocused ? 'black' : 'black',
    }),

    singleValue: (styles) => ({
      ...styles,
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: 1,
      color: option ? '#ffffff' : '#000000',
    }),
  };

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon
            name="icon-search"
            size={'xsmall'}
            style={{ color: option ? '#FFF' : '#000' }}
          />
        </components.DropdownIndicator>
      )
    );
  };

  const getCities = useCallback(async (query?: string, language?: any) => {
    const params = new URLSearchParams(query);
    try {
      const response = await queryService.run<UIDiscoverSearchOption[]>(
        app.environment,
        {
          name: 'mapsorgusu',
          language: language,
          query: params.toString(),
        },
        SEARCH_CITY
      );

      if (response) {
        setCities(response.data);
      }
    } catch (err) {
      err;
    }
  }, []);

  useEffect(() => {
    getCities('', app.language);
  }, []);

  const handleChange = (option: any | null) => {
    if (option) {
      onChangeTab(option.region);
      setOption(option);
      setActiveCity(option.value);
    } else {
      onChangeTab('');
      setOption(null);
      setActiveCity('');
    }
  };

  useEffect(() => {
    if (activeRegion?.id !== option?.region) {
      setActiveCity('');
      setOption(null);
    }
  }, [activeRegion]);

  return (
    <div>
      <ReactSelect
        options={cities}
        isClearable={true}
        onChange={handleChange}
        placeholder={app.settings.translations['selectCities']}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
        styles={customStyles}
      />
    </div>
  );
};

export default DiscoverSearch;
