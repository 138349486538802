import React, { useRef, useEffect, useState } from "react";

import { Annotation } from "react-simple-maps";

import { UIAnnotation } from "./types";
import Anchor from "@/components/base/anchor";

const DynamicAnnotation: React.FC<UIAnnotation> = ({ text, subject, dx, dy, box, link }) => {
  const textRef = useRef<HTMLLabelElement | null>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      const newWidth = textRef.current.getBoundingClientRect().width + 70;
      setWidth(newWidth);
    }
  }, [text]);

  if (typeof subject?.lat !== "number" || typeof subject?.lng !== "number") {
    return null;
  }

  const subjectCoordinate: [number, number] = [subject.lat, subject.lng];

  return (
    <Annotation
      subject={subjectCoordinate}
      dx={dx}
      dy={dy}
      curve={0}
      connectorProps={{
        className: "annotation-path",
        stroke: "#FFF",
        strokeWidth: 3,
        strokeLinecap: "round",
      }}
    >
      {dx && dy && (
        <circle cx={-dx} cy={-dy} r={5} fill="#FFF" />
      )}

      <foreignObject x={-width / 2} y={box?.dy} width={width} height="35">
        <Anchor href={link?.path} className="annotation-box">
          <label ref={textRef}>{text}</label>
        </Anchor>
      </foreignObject>
    </Annotation>
  );
};

export default DynamicAnnotation;
